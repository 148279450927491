import requestTBE from '@/utils/requestToBackend.js';

export const apiPostGetTicketInfo =
    data => requestTBE("post",'/api/v1/ticket/info/'+ data.uuid, data.data)
            .then(function(res){
                return res.data ;
            });
export const apiPostCheckTicket =
    data => requestTBE("post",'/api/v1/ticket/check/'+ data.uuid, data.data)
            .then(function(res){
                return res.data ;
            });

