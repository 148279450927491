var render = function render(){var _vm=this,_c=_vm._self._c;return _c('transition',{attrs:{"name":"modal"}},[_c('div',{staticClass:"modal-mask"},[_c('div',{staticClass:"modal-wrapper"},[_c('div',{staticClass:"modal-container font-modal"},[(_vm.need_checked)?_c('img',{attrs:{"src":require("@/assets/1920_icon_waiting_circle.svg")}}):(_vm.success)?_c('img',{attrs:{"src":require("@/assets/1920_icon_success.svg")}}):(!_vm.success)?_c('img',{attrs:{"src":require("@/assets/1920_icon_error.svg")}}):_vm._e(),_c('div',{staticClass:"modal-body status-message",class:{ 
           'font-selected': _vm.need_checked,
           'font-success': _vm.success&&!_vm.need_checked,
           'font-fail': !_vm.success&&!_vm.need_checked,
          }},[(_vm.need_checked)?_c('span',[_vm._v("是否驗證半票")]):(_vm.success)?_c('span',[_vm._v("驗證成功")]):(!_vm.success)?_c('span',[_vm._v("驗證失敗")]):_vm._e()]),_c('div',{staticClass:"modal-body message",class:{ 
           'font-success': _vm.success,
           'font-fail': !_vm.success,
          }},[(!_vm.need_checked)?_c('div',[_vm._v(" "+_vm._s(_vm.message)+" ")]):_c('div',[_c('button',{staticClass:"btn btn-primary btn-layout",on:{"click":function($event){return _vm.$emit('check')}}},[_vm._v(" 驗證 ")]),_c('button',{staticClass:"btn btn-primary btn-layout",on:{"click":function($event){return _vm.$emit('close')}}},[_vm._v(" 取消 ")])])])])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }