<template>
    <div class="vh-vw-100-bg dashboard-style"> 
        <div class="row mx-4 d-flex justify-content-between header-height">
            <div class="col-7 h-100">
                <div class="row p-4 h-25 m-4">
                    <div>
                        <Logo />
                    </div>
                </div>
            </div>
            <div class="col-5">
              <div
                class="my-4 date d-flex align-items-center justify-content-center"
              >
                <div class="col-6 mx-3 date-left">
                  {{ date.time }}
                </div>
                <div class="col-6 mx-3 date-right">
                  <div>{{ date.md }}</div>
                  <div>星期{{ date.day }}</div>
                </div>
              </div>
            </div>
        </div>
        <div>
            <CheckTicketBoard
            :date="date"
            />
        </div>
        <div class="bg-white row header-height d-flex align-items-center justify-content-center footer-font">
            請將船票QR Code對準掃描處進行驗票
        </div>

        
    </div>
</template>
<script>
import CheckTicketBoard from '@/components/check-ticket-board.vue'
import Logo from "@/components/layout-components/logo.vue";

export default {
    components: {
        CheckTicketBoard,
        Logo,
    },
    
    data() {
        return {
            date: {
                year: "",
                md: "",
                day: "",
                time: "00:00",
            },
        }
    },
    methods: {
        startSetInterval() {
            window.setInterval(() => this.setDashboardTime(), 1000);
        },
        setDashboardTime() {
            let getDate = this.getToday_DashBoard();
            let getTime = this.getNowTime();
            this.date.md = getDate.md;
            this.date.day = getDate.day;
            this.date.year = getDate.year;
            this.date.time = getTime;
        },
        init() {
            this.setDashboardTime();
            this.startSetInterval();
        },
    },
    mounted() {
        this.init();
    }
    
}
</script>
<style scope>
.vh-vw-100-bg {
  width: 100vw;
  height: 100vh;
}
.dashboard-style {
  background-image: linear-gradient(var(--linear-gradient-top), var(--linear-gradient-bottom));
  width: 100vw;
  height: 100vh;
}

.date {
  height: 180px;
}
.date .date-left {
  text-align: right;
  font-size: 130px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
}
.date .date-right {
  text-align: left;
  font-size: 50px;
  line-height: 50px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
  letter-spacing: 2.12px;
}

.header-height {
    height: 180px;
}
.footer-font {
    font-size: 90px;
    color: var(--maincolor);
}
</style>