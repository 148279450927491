<template>
  <transition name="modal">
    <div class="modal-mask">
      <div class="modal-wrapper">
        <div 
         class="modal-container font-modal"
        >
          <img 
           v-if="need_checked"
           src="@/assets/1920_icon_waiting_circle.svg"
          >
          <img 
           v-else-if="success"
           src="@/assets/1920_icon_success.svg"
           >
          <img 
           v-else-if="!success"
           src="@/assets/1920_icon_error.svg"
          >
          <div 
           class="modal-body status-message"
           :class="{ 
             'font-selected': need_checked,
             'font-success': success&&!need_checked,
             'font-fail': !success&&!need_checked,
            }"
          >
            <span v-if="need_checked">是否驗證半票</span>
            <span v-else-if="success">驗證成功</span>
            <span v-else-if="!success">驗證失敗</span>
          </div>
          <div 
           class="modal-body message"
           :class="{ 
             'font-success': success,
             'font-fail': !success,
            }"
          >
            <div v-if="!need_checked">
                {{message}}
            </div>
            <div v-else>
                <button 
                 class="btn btn-primary btn-layout"
                 v-on:click="$emit('check')"
                >
                驗證
                </button>
                <button 
                 class="btn btn-primary btn-layout"
                 v-on:click="$emit('close')"
                >
                 取消
                </button>
            </div>
          </div>

        </div>
      </div>
    </div>
  </transition>
</template>
<script>
export default {
    props: {
        success: Boolean,
        message: String,
        need_checked: Boolean,
    },
    methods: {

    },
    watch: {
        'need_checked': function() {
            if ( !this.need_checked ) {
                let vm = this ;
                setTimeout(() => {
                    vm.$emit('close') ;
                }, 3000);
            }
        }
    },
    mounted() {
        let vm = this ;
        if ( !this.need_checked ) {
            setTimeout(() => {
                vm.$emit('close') ;
            }, 3000);
        }
    }
}
</script>
<style scope>
.modal-mask {
  position: fixed;
  z-index: 9998;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  display: table;
  transition: opacity 0.3s ease;
}

.modal-wrapper {
  display: table-cell;
  vertical-align: middle;
}

.modal-container {
  width: 1200px;
  height: 550px;
  margin: 0px auto;
  padding: 20px 30px;
  background-color: #fff;
  border-radius: 25px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.33);
  transition: all 0.3s ease;
  font-family: Helvetica, Arial, sans-serif;
}

.modal-header h3 {
  margin-top: 0;
  color: #42b983;
}

.modal-body {
  margin: 20px 0;
}

.modal-default-button {
  float: right;
}

/*
 * The following styles are auto-applied to elements with
 * transition="modal" when their visibility is toggled
 * by Vue.js.
 *
 * You can easily play with the modal transition by editing
 * these styles.
 */

.modal-enter {
  opacity: 0;
}

.modal-leave-active {
  opacity: 0;
}

.modal-enter .modal-container,
.modal-leave-active .modal-container {
  -webkit-transform: scale(1.1);
  transform: scale(1.1);
}

.font-modal {
  font-weight: bold;
  text-align: center;
}
.font-modal .font-success {
  color: #01D199;
}
.font-modal .font-fail {
  color: #F42F41;
}
.font-modal .font-selected {
  color: #0774EA
}
.font-modal .status-message{
  font-size: 140px;
}
.font-modal .message{
  font-size: 50px;
}
.btn-layout {
    margin-left: 100px;
    margin-right: 100px;
    border-radius: 50px !important;
    width: 250px;
    height: 100px;
    font-size: 50px !important;
}

</style>