<template>
    <div>
          <div class="px-4 d-flex justify-content-around">
            <div class="col-6">
              <div class="row p-4 m-4">
                <div class="d-flex">
                  <div
                    class="px-4 round-describe d-flex align-items-center justify-content-center"
                  >
                    <div class="text">目的地</div>
                  </div>
                  <div class="mx-4 text-station-notice d-flex align-items-center">
                    請點選白色框框以更改目的地
                  </div>
                </div>
                
                <div class="descibe-to-station my-4" v-on:click="changeStation()">
                  <span class="cn-station">{{ station.to }}</span>
                  <span class="en-station mx-4">{{ station.to_en }}</span>
                </div>

                <div class="my-4"></div>

                <div class="d-flex p-0">
                  <div
                    class="px-4 round-describe d-flex align-items-center justify-content-center"
                  >
                    <span class="text">出發時間</span>
                  </div>
                  <div
                    class="px-4 mx-4 text-white d-flex align-items-end pop-add-ship"
                  >
                    <span v-if="shippingList[ship_index].isSpecial">機動加班</span>
                  </div>
                </div>

                <div class="descibe-ship-time my-4 d-flex">
                  <span class="ship-time">{{ shippingList[ship_index].data.time }}</span>
                  <span class="ship-name px-2 mx-4">{{
                    shippingList[ship_index].data.ferry_name
                  }}</span>
                  <div style="width:60px">
                    <div class="arrow_up" v-on:click="selectShip('pre')"></div>
                    <div class="arrow_down" v-on:click="selectShip('next')"></div>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-5">
              <div
                class="row circle-progress-layout d-flex justify-content-center my-4"
              >
                <vue-ellipse-progress
                  :progress="progressData"
                  color="#ffffff"
                  :emptyColor="{
                    colors: [{ color: '#ffffff', offset: '0', opacity: '0.2' }],
                  }"
                  :size="480"
                  fontSize="4rem"
                >
                  <div class="text-white">
                    <div class="passenger-desc">已登船</div>
                    <div>
                      <span class="passenger">{{ shippingList[ship_index].data.boarding }}</span>
                      人
                    </div>
                  </div>
                </vue-ellipse-progress>
              </div>
              <div
                class="row d-flex align-items-center justify-content-center passenger-limit"
              >
                <span class="desc pt-4">乘載人數</span>
                <span class="number pb-4">{{ shippingList[ship_index].data.seat }}</span>
                <span class="desc pt-4">人</span>
              </div>
            </div>
          </div>
          <input 
           type="text" 
           class="hidden-input"
           ref="scaninput" 
           v-model="input_data"
           v-on:keyup.enter="scanInput()"
          >
          <!-- Modal -->
          <Modal 
            v-if="bubble_data.showing"
            :success="bubble_data.success"
            :message="bubble_data.message"
            :need_checked="bubble_data.need_checked"
            @close="bubble_data.showing=false"
            @check="sendCheckTicketRequest"
          />
        </div>
</template>
<script>
import { apiGetSailings } from "@/apis/booking.js";
import { apiPostGetTicketInfo } from "@/apis/checkTicket.js";
import { apiPostCheckTicket } from "@/apis/checkTicket.js";
import Modal from "@/components/modal.vue";

export default {
  components: {
    Modal,
  },
  props: {
    date: Object
  },
  data() {
    return {
      route: "DL",
      station: {
        from: "",
        from_en: "",
        to: "",
        to_en: "",
      },
      nowShip: {
        time: "",
        quota: "",
        used: "",
        ferryName: "",
        isSpecial: false,
      },
      shippingList: [],
      ship_index: 0,
      input_data: "",
      bubble_data: {
        showing: false,
        success: false,
        message: "",
        need_checked: false,
      }
    };
  },
  computed: {
    progressData() {
      return (this.shippingList[this.ship_index].data.boarding * 100) / this.shippingList[this.ship_index].data.seat;
    },
  },
  watch: {},
  methods: {
    setIntervalForForceHiddenInput() {
      const vm = this;
      window.setInterval( 
        function() { 
          try {
            if ( !vm.bubble_data.showing )
              vm.$refs.scaninput.focus(); 
          } catch ( e ) {
            console.log(e);
          }
        },
        500);
    },
    scanInput() {
      console.log('scanData',this.input_data);
      if ( this.shippingList[this.ship_index].data.boarding < this.shippingList[this.ship_index].data.seat ) {
        this.sendGetInfoRequest();
      }
      else {
        this.bubble_data.need_checked = false;
        this.bubble_data.success = false;
        this.bubble_data.message = '船班已額滿';
        this.bubble_data.showing = true;
        this.input_data = "";
      }
      
    },
    async sendGetInfoRequest() {
      
      let md = this.mmddCnStrTommdd(this.date.md);
      let requestData = {
        boardingTime: 
          this.date.year + '-' + md.m + '-' + md.d + ' ' 
        + this.date.time + ':00',
        route: this.route,
        name: this.shippingList[this.ship_index].data.ferry_name,
      }
      console.log(requestData);

      let result = await apiPostGetTicketInfo({
        uuid: this.input_data,
        data: requestData,
      });

      if ( result.status === 200 ) {
        console.log(result);
        if ( result.data.sub_type === '半票' ) {
          console.log('半票');
          this.bubble_data.need_checked = true;
          this.bubble_data.success = false;
          this.bubble_data.message = '';
          this.bubble_data.showing = true;
        }
        else {
          // 全票則直接再送一次 ;
          console.log('非半票');
          await this.sendCheckTicketRequest();
        }
      }
      else {
        console.log(result);
        if ( result.status === 400 ) {
          this.bubble_data.need_checked = false;
          this.bubble_data.success = false;
          if ( result.message === "查無相關票券" ) {
            this.bubble_data.message = '查無相關票券';
          }
          else if ( result.message === "航向錯誤，票券方向為東港出發" || result.message === "航向錯誤，票券方向為小琉球出發" ) {
            this.bubble_data.message = result.message;
          }
          else if ( result.message === "查無此訂單" ) {
            this.bubble_data.message = '查無此訂單';
          }
          else if ( result.message === "查訂單錯誤" ) {
            this.bubble_data.message = '查訂單錯誤';
          }
          else if ( result.message === "票券已驗證" ) {
            this.bubble_data.message = '票券已驗證';
          }
          else if ( result.message === "票券已退貨" ) {
            this.bubble_data.message = '票券已退票';
          }
          else {
            this.bubble_data.message = result.message;
          }
          this.bubble_data.showing = true;
          this.input_data = ""; // clear input data ;
        }
      }

    },
    async sendCheckTicketRequest() {

      let md = this.mmddCnStrTommdd(this.date.md);
      let requestData = {
        boardingTime: 
          this.date.year + '-' + md.m + '-' + md.d + ' ' 
        + this.shippingList[this.ship_index].data.time + ':00',
        route: this.route,
        name: this.shippingList[this.ship_index].data.ferry_name,
      }
      console.log(requestData);

      let checked_result = await apiPostCheckTicket({
        uuid: this.input_data,
        data: requestData,
      });
      if ( checked_result.status === 200 ) {
        console.log('success',checked_result);
        this.bubble_data.need_checked = false;
        this.bubble_data.success = true;
        this.bubble_data.message = '乘客 - ' + checked_result.data.name ;
        this.bubble_data.showing = true;
      }
      else {
        console.log('fail',checked_result);
      }
      this.input_data = ""; 

    },
    async changeStation() {
      if ( this.route === "DL" ) {
        this.route = "LD";
        this.getStation();
        await this.getShip(this.getToday(), this.route);
      }
      else if ( this.route === "LD" ) {
        this.route = "DL";
        this.getStation();
        await this.getShip(this.getToday(), this.route);
      }
      this.getNearestShipping(this.getToday());
    },
    startSetInterval() {
      this.setIntervalForForceHiddenInput();
      window.setInterval(() => this.getShip(this.getToday(), this.route), 5000);
    },
    setDashboardTime() {
      let getDate = this.getToday_DashBoard();
      let getTime = this.getNowTime();
      this.date.md = getDate.md;
      this.date.day = getDate.day;
      this.date.time = getTime;
    },
    async init() {
      this.getStation();
      this.setDashboardTime();
      await this.getShip(this.getToday(), this.route);
      this.getNearestShipping(this.getToday());
      this.startSetInterval();
    },
    getNearestShipping(date) {
      for ( let i = 0 ; i < this.shippingList.length ; i++ ) {
        if ( this.checkTime(date,this.shippingList[i].data.time) || i === this.shippingList.length-1 ) {
          this.ship_index = i ;
          break;
        }
      }
    },
    getStation() {
      if (this.route === "DL") {
        this.station.from = "東港";
        this.station.from_en = "Donggang".toUpperCase();
        this.station.to = "小琉球";
        this.station.to_en = "Liuqiu".toUpperCase();
      } else if (this.route === "LD") {
        this.station.from = "小琉球";
        this.station.from_en = "Liuqiu".toUpperCase();
        this.station.to = "東港";
        this.station.to_en = "Donggang".toUpperCase();
      }
    },
    async getShip(date, routeCode) {
      // api 取得 航班時間;
      let data = {
        date: date,
        route_code: routeCode,
      };
      this.shippingList = [];

      let searchResult = await apiGetSailings(data);
      if (searchResult.status === 200) {
        for (let i = 0; i < searchResult.data.length; i++) {
          let oneRow = {
            data: searchResult.data[i],
            disable: false,
            isSpecial: false,
            isFull: false,
          };
          // oneRow.disable = !this.checkTime(date, oneRow.data.time);

          this.shippingList.push(oneRow);

          // if (!oneRow.disable) {
          //   this.nowShip.time = oneRow.data.time;
          //   this.nowShip.quota = oneRow.data.quota;
          //   this.nowShip.used = oneRow.data.boarding;
          //   this.nowShip.ferryName = oneRow.data.ferry_name;
          //   this.nowShip.seat = oneRow.data.seat;
          //   break;
          // } else if (
          //   this.nowShip.time === "" &&
          //   i === searchResult.data.length - 1
          // ) {
          //   this.nowShip.time = oneRow.data.time;
          //   this.nowShip.quota = oneRow.data.quota;
          //   this.nowShip.used = oneRow.data.boarding;
          //   this.nowShip.ferryName = oneRow.data.ferry_name;
          //   this.nowShip.seat = oneRow.data.seat;
          // }
        }
      } else {
        console.log(searchResult);
      }
    },
    checkTime(date, time) {
      // 檢查輸入的時間是否過期(超過現在時間+5min) ;
      // console.log('+',this.hmToMinutes(time),this.hmToMinutes(this.getNowTime()));
      if (date > this.getToday()) {
        return true;
      } else if (
        date === this.getToday() && 
        this.hmToMinutes(time)+5 > this.hmToMinutes(this.getNowTime())
      ) {
        return true;
      }
      return false;
    },
    selectShip( operator ) {
      if ( operator === 'pre' && this.ship_index > 0 ) {
        this.ship_index-- ;
      }
      else if ( operator === 'next' && this.ship_index < this.shippingList.length ) {
        this.ship_index++ ;
      }
    }
  },
  mounted() {
    this.init();
  },
};
</script>
<style scoped>
.text-color-descr-dark {
  color: var(--maincolor);
}
.round-describe {
  background-color: #ffffff;
  border-radius: 13px;
  width: fit-content;
  height: 72px;
}
.round-describe .text {
  text-align: left;
  font: bold 43px/2px Noto Sans;
  color: var(--maincolor);
}
.descibe-to-station {
  text-align: center;
  color: #ffffff;
  opacity: 1;
  border-style: solid;
  border-width: 5px;
  border-radius: 25px;
}
.descibe-to-station .cn-station {
  font-size: 100px;
  font: bold Noto Sans;
}
.descibe-to-station .en-station {
  font-size: 84px;
  font: bold 89px/3px Montserrat;
}
.pop-add-ship {
  font-size: 45px;
  letter-spacing: 1.8px;
  font-weight: 900;
  font-family: Noto Sans;
}
.descibe-ship-time {
  text-align: left;
  color: #ffffff;
  opacity: 1;
}
.descibe-ship-time .ship-time {
  font-size: 110px;
  font-weight: bold;
  font-family: Montserrat;
}
.descibe-ship-time .ship-name {
  font-size: 110px;
  font-weight: bold;
  font-family: Noto Sans;
}

.date {
  height: 180px;
}
.date .date-left {
  text-align: left;
  font-size: 130px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
}
.date .date-right {
  text-align: left;
  font-size: 50px;
  font-weight: bold;
  font-family: Montserrat;
  color: #ffffff;
  letter-spacing: 2.12px;
}

.circle-progress-layout {
  height: 450px;
}
.circle-progress-layout .passenger {
  font-size: 160px;
  font-weight: bold;
}
.circle-progress-layout .passenger-desc {
  font-size: 50px;
}
.passenger-limit {
  color: #ffffff;
  height: 100px;
}
.passenger-limit .desc {
  font-size: 48px;
  width: fit-content;
  font-weight: 900;
  font-family: Noto Sans;
}
.passenger-limit .number {
  font-size: 135px;
  width: fit-content;
  height: fit-content;
  font-weight: bold;
  font-family: Montserrat;
}

.h-30 {
  height: 30vh;
}

.text-station-notice {
  color: white;
  font-size: 35px;
}

.arrow_up {
  display: block;
  width: 30px;
  height: 50px;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 40px solid #ffffff;
  font-size: 0;
  line-height: 0;
  margin-top: 30px;
}
.arrow_down {
  display: block;
  width: 30px;
  height: 50px;
  border-left: 30px solid transparent;
  border-right: 30px solid transparent;
  border-bottom: 40px solid #ffffff;
  font-size: 0;
  line-height: 0;
  transform: rotate(180deg);
  margin-top: 30px;
}
.hidden-input {
  width: 0.01px;
  height: 0.01px;
  border-width: 0px;
  border-style: none;
  color: transparent;
  background-color: transparent;
  border-color: transparent;
}
.hidden-input:focus {
  border-width: 0px;
  border-color: transparent;
}
input:focus {
  border-width: 0px;
  border-color: transparent;
}
</style>
