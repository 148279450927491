import request from '@/utils/request.js';

export const apiGetRoutes = () =>
            request("get",'/api/v1/routes')
            .then(function(res){
                // console.log('apiGetRoutes:',res.data);
                return res.data ;
            });

export const apiGetSailings =
    data => request("get",'/api/v1/sailings',data)
            .then(function(res){
                // console.log('apiGetSailings:',res.data);
                return res.data ;
            });

export const apiGetFares = data =>
            request("get",'/api/v1/fares',data)
            .then(function(res){
                // console.log('apiGetFares:',res.data);
                return res.data ;
            });

export const apiPostBookingTickets =
    data => request("post",'/api/v1/booking',data)
            .then(function(res){
                // console.log('apiPostBookingTickets:',res.data);
                return res.data ;
            });