import Vue from 'vue'
import VueRouter from 'vue-router'

import CheckTicket from '../views/CheckTicket.vue'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: CheckTicket
  },

]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
